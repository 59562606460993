import { mapActions, mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';

//---helpers
import { PRODUCTS_ORDER_KEY } from '@/helpers';
import { globalLoader, pluralizate } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import ProductsBlock from '@/components/products/ProductsBlock/index.vue';
import SeriesCard from '@/components/cards/SeriesCard/index.vue';
import Pagination from '@/components/@general/Pagination/index.vue';
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';

export default {
  name: 'Catalog',
  mixins: [dynamicHead],
  components: {
    BreadcrumbsSimple,
    ProductsBlock,
    SeriesCard,
    Pagination
  },
  data() {
    return {
      catalogSeries: [],
      catalogProducts: [],
      catalogProductsMeta: null,
      isCatalogProductsLoading: false
    };
  },
  created() {
    this._loadContent();
  },
  watch: {
    $route: {
      immediate: false,
      handler(newVal) {
        if (newVal) {
          this._getProducts();
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList',
      metaData: 'meta/metaData'
    }),
    breadcrumbsTitle() {
      return this.catalogProductsMeta && this.catalogProductsMeta.currentPage > 1
        ? `${this.$t('label.page')} ${this.catalogProductsMeta.currentPage}`
        : this.$t('page.catalog.title');
    },
    breadcrumbsList() {
      return this.catalogProductsMeta && this.catalogProductsMeta.currentPage > 1
        ? [{ title: this.$t('page.catalog.title'), route: { name: 'products' } }]
        : [];
    },
    catalogTitle() {
      let total = 0;
      let currentPage = 1;
      if (this.catalogProductsMeta) {
        total = this.catalogProductsMeta.total;
        currentPage = this.catalogProductsMeta.currentPage;
      }

      let plurazitePart = pluralizate(
        total,
        this.$t('label.products.countOne'),
        this.$t('label.products.countSimple'),
        this.$t('label.products.countMany')
      );

      let title = `${this.$t('block.productsBlock.titleShow')} ${total} ${plurazitePart}`;

      return currentPage > 1 ? `<h1>${this.$t('label.page')}: ${currentPage} </h1> ${title}` : `<h2>${title}</h2>`;
    },
    productsList() {
      if (!this.isAuthenticated || !this.favourites) {
        return this.catalogProducts;
      }

      const favourites = _.map(this.favourites, 'productId');
      const markAsSelected = (product) => {
        product.isFavorite = favourites.includes(product.id);
        return product;
      };

      return _.map(this.catalogProducts, markAsSelected);
    }
  },
  methods: {
    ...mapActions({
      getCatalogSeries: 'catalog/GET_CATALOG_CATEGORIES',
      getCatalogProducts: 'catalog/GET_CATALOG_PRODUCTS',
      getMetaData: 'meta/GET_META_DATA'
    }),
    ...mapMutations({}),
    _getProducts() {
      this.isCatalogProductsLoading = true;
      let { params, query } = this.$route;
      let { page } = params;
      let { orderby } = query;
      return this.getCatalogProducts({
        page: page ? Number(page) : 1,
        sort_filter_type: orderby ? orderby : PRODUCTS_ORDER_KEY.BESTSELLERS
      })
        .then((result) => {
          this.catalogProducts = result.data;
          this.catalogProductsMeta = result.meta;
        })
        .finally(() => {
          this.isCatalogProductsLoading = false;
        });
    },
    async _loadContent() {
      globalLoader(true);
      let promiseCollection = [];
      let { page } = this.$route.params;
      if (!page) {
        promiseCollection.push(this.getCatalogSeries().then((result) => this._prepareSeries(result)));
      }
      promiseCollection.push(this._getProducts());
      promiseCollection.push(
        this.getMetaData('katalog').then(() => {
          this._setMetaParams();
        })
      );
      await Promise.all(promiseCollection);
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, metaDescription, image, metaKeywords } = this.metaData;
      if (metaTitle) this.metaParams.title = `${metaTitle}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      if (metaKeywords) this.metaParams.keywords = metaKeywords;
      this.setMeta();
    },
    _prepareSeries(data) {
      let { brandSeries, brands } = data;
      this.catalogSeries = [...brandSeries, ...brands];
    }
  }
};
